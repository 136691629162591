export const units = {
  ampoule: 'Ampola(s)',
  box: 'Caixa(s)',
  pill: 'Cápsula(s)',
  capsule: 'Comprimidos(s)',
  dose: 'Dose(s)',
  packing: 'Embalagem(ns)',
  envelope: 'Envelope(s)',
  bottle: 'Frasco(s)',
  drop: 'Gota(s)',
  tablet: 'Pastilha(s)',
  sachet: 'Sachê(s)',
  unit: 'Unidade(s)',
  continuous: 'Uso contínuo',
};

export function getName(value) {
  return units[value];
}
