<template>
  <dx-modal title="Gerenciar anamnese"
            :value="show"
            @input="close"
            size="lg">
    <div class="modal-template-anamnesis-detail" v-if="form.professionals.length > 1">
      <strong class="text-warning">
        * Não é possível editar um documento compartilhado. Crie sua própria anamnese
      </strong>
      <div class="card-detail mt-2">
        <small>Título</small>
        <div class="card-detail-title">{{ form.name }}</div>
        <div class="card-detail-data">
          <div v-html="form.data" />
        </div>
      </div>
      <div class="card-detail">
        <small>Compartilhado com</small>
        <div class="card-detail-data">
          <div v-for="(professional, i) in form.professionals"
               :key="professional.key">
            {{ i + 1 }} - {{ professional.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="modal-template-anamnesis-edit" v-else>
      <div class="columns form-group">
        <div class="column col-8 col-sm-12 form-group"
             :class="{'has-error': $v.form.name.$error}">
          <label for="name" class="form-label">Nome</label>
          <input type="text" id="name" name="name"
                 v-model="form.name" class="form-input"
                 @change="$v.form.name.$touch()">
          <template v-if="$v.form.name.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.name.required">Campo obrigatório</div>
          </template>
        </div>
      </div>
      <div class="divider" />
      <div class="btn-group btn-group-block mt-2 mb-2">
        <button class="btn btn-sm btn-gray mr-1"
                @click="insertTag('date')">
          Data atual
        </button>
        <button class="btn btn-sm btn-gray mr-1"
                @click="insertTag('hour')">
          Hora atual
        </button>
        <button class="btn btn-sm btn-gray mr-1"
                @click="insertTag('cid')">
          CID-10
        </button>
        <button class="btn btn-sm btn-gray mr-1"
                @click="insertTag('patient_name')">
          Nome do paciente
        </button>
        <button class="btn btn-sm btn-gray mr-1"
                @click="insertTag('patient_gender')">
          Sexo
        </button>
        <button class="btn btn-sm btn-gray mr-1"
                @click="insertTag('patient_birth_date')">
          Data de Nascimento
        </button>
        <button class="btn btn-sm btn-gray mr-1"
                @click="insertTag('patient_age')">
          Idade
        </button>
        <button class="btn btn-sm btn-gray mr-1"
                @click="insertTag('patient_cpf')">
          CPF
        </button>
        <button class="btn btn-sm btn-gray mr-1"
                @click="insertTag('patient_phone')">
          Telefone
        </button>
        <button class="btn btn-sm btn-gray"
                @click="insertTag('patient_address')">
          Endereço do paciente
        </button>
      </div>
      <st-editor id="document-editor" ref="editorDocument"
                 v-model="form.data"></st-editor>
    </div>
    <template slot="footer">
      <button class="btn btn-primary mr-1"
              @click="save()" v-if="form.professionals.length <= 1"
              :disabled="saving" :class="{loading: saving}">
        Salvar
      </button>
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import formMixin from '@/mixins/form';

export default {
  mixins: [formMixin],
  props: {
    show: {
      type: Boolean,
    },
    id: {
      type: String,
    },
    user: {
      type: Object,
    },
  },
  data() {
    return {
      path: '/anamnesis-templates',
      isNew: true,
      loading: false,
      saving: false,
      form: this.blankForm(),
    };
  },
  async mounted() {
    this.isNew = this.id === '';
    if (!this.isNew) {
      this.form.id = this.id;
      this.load();
    }
  },
  validations() {
    return {
      form: {
        name: { required },
        active: { required },
      },
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    load() {
      this.loading = true;

      return this.$http
        .get(`${this.path}/${this.form.id}`)
        .then(({ data }) => {
          if (data) {
            this.form = data;
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error || this.form.professionals.length > 1) {
        return;
      }

      this.saving = true;

      const data = this.clone(this.form);

      data.professionals = [{
        id: this.user.id,
        name: this.user.name,
      }];

      const request = !data.id
        ? this.$http.post(this.path, data)
        : this.$http.put(`${this.path}/${data.id}`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(() => {
          this.$toast.show('Registro salvo');
          this.close();
        })
        .catch(() => {}).then(() => {
          this.saving = false;
        });
    },
    insertTag(name) {
      this.$refs.editorDocument.editor.model.change((writer) => {
        writer.insertText(`[${name}]`,
          this.$refs.editorDocument.editor.model.document.selection.getFirstPosition());
      });
    },
    blankForm() {
      return {
        id: '',
        name: '',
        size: 'A4',
        professionals: [],
        active: true,
        data: '',
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.modal-template-anamnesis-detail {
  .card-detail {
    background-color: lighten($gray-color-light, 6%);
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    margin-bottom: $layout-spacing;
    padding: $layout-spacing;
    .card-detail-title {
      font-weight: bold;
      color: $primary-color;
      margin-bottom: $layout-spacing;
    }
    .table th, .table td {
      font-size: 0.52rem;
    }
  }
}
.modal-template-anamnesis-edit {
  margin-left: auto;
  margin-right: auto;
  max-width: $control-width-lg;
  .document-editor-body {
    .ck.ck-content.ck-editor__editable {
      min-height: 28rem;
      padding: 15px 72px;
    }
  }
}
</style>
