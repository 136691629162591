<template>
  <dx-modal title="Gerenciar protocolo"
            :value="show"
            @input="close"
            size="lg">
    <div class="modal-template-request-detail" v-if="form.professionals.length > 1">
      <strong class="text-warning">
        * Não é possível editar um protocolo compartilhado. Crie seu próprio protocolo
      </strong>
      <div class="card-detail mt-2">
        <small>Título</small>
        <div class="card-detail-title">{{ form.name }}</div>
        <div class="card-detail-data">
          <small>Exames</small>
          <div v-for="item in form.expenses" :key="item.key">
            <span class="mr-2">{{ item.code | tuss }} - {{ item.name }}</span>
            <small>x{{ item.quantity }}</small>
          </div>
        </div>
      </div>
      <div class="card-detail">
        <small>Compartilhado com</small>
        <div class="card-detail-data">
          <div v-for="(professional, i) in form.professionals"
               :key="professional.key">
            {{ i + 1 }} - {{ professional.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="modal-template-request-edit" v-else>
      <div class="columns form-group">
        <div class="column col-8 col-sm-12 form-group"
             :class="{'has-error': $v.form.name.$error}">
          <label for="name" class="form-label">Nome</label>
          <input type="text" id="name" name="name"
                 v-model="form.name" class="form-input"
                 @change="$v.form.name.$touch()">
          <template v-if="$v.form.name.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.name.required">Campo obrigatório</div>
          </template>
        </div>
        <div class="column col-4 col-sm-12 form-group"
             :class="{'has-error': $v.form.type.$error}">
          <label for="type" class="form-label">Tipo</label>
          <select id="type" name="type" class="form-select"
                  v-model="form.type"
                  @change="$v.form.type.$touch()">
            <option value="">Selecione</option>
            <option value="exam">Solicitação de exames</option>
            <option value="hospitalization">Solicitação de internação</option>
          </select>
          <template v-if="$v.form.type.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.type.required">Campo obrigatório</div>
          </template>
        </div>
      </div>
      <div class="mt-2">Exames</div>
      <div class="columns">
        <div class="column col-8">
          <dx-autocomplete
            id="expense"
            ref="expense"
            v-model="expense"
            :source="findExpense"
            label="expense"
            track-by="expense-id"
            @select="setExpense"
            :debounce="800"
            placeholder="Pesquisar por código ou descrição">
            <button slot="action"
                    class="btn btn-neutral btn-action input-group-btn btn-icon">
              <fa-icon :icon="['fal', 'search']"/>
            </button>
            <template v-slot="{ item }">
              <span>
                <fa-icon class="text-primary" :icon="['fal', 'file-medical-alt']"></fa-icon>
                {{ item.name }} ({{ item.code | tuss }})
              </span>
              <span>{{ item.display }}</span>
            </template>
          </dx-autocomplete>
        </div>
      </div>
      <div class="empty mt-2" v-if="form.expenses.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <p class="empty-title h5">Lista de exames</p>
        <p class="empty-subtitle">
          Nenhum exame adicionado. Informe o nome ou código do exame no campo acima.
        </p>
      </div>
      <table class="table" v-else>
        <thead>
        <tr>
          <th style="width:50px">#</th>
          <th style="width:100px">Código</th>
          <th>Descrição</th>
          <th class="text-center" style="width:90px">Quantidade</th>
          <th style="width:40px"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in form.expenses" :key="i">
          <td>{{ i + 1 }}</td>
          <td>{{ item.code | tuss }}</td>
          <td>
            <input type="text" class="form-input input-sm" v-model="item.name">
          </td>
          <td class="text-center">
            <input
              type="number" class="form-input input-sm"
              v-model="item.quantity" min="1"
            >
          </td>
          <td class="text-right">
            <button class="btn btn-sm btn-action btn-icon btn-error"
                    @click="removeExpense(i)">
              <fa-icon :icon="['fal', 'times']"></fa-icon>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <template v-if="form.type === 'hospitalization'">
        <div class="mt-2">OPMs</div>
        <div class="columns">
          <div class="column col-8">
            <dx-autocomplete
              id="opme"
              ref="opme"
              v-model="opme"
              :source="findOpme"
              label="opme"
              track-by="opme-id"
              @select="setOpme"
              :debounce="800"
              placeholder="Pesquisar por código ou descrição">
              <button slot="action"
                      class="btn btn-neutral btn-action input-group-btn btn-icon">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
              <template v-slot="{ item }">
                <div>
                  <fa-icon class="text-primary" :icon="['fal', 'file-medical-alt']"></fa-icon>
                  {{ item.technicalName }} ({{ item.code | tuss }})
                </div>
                <div>{{ item.model }}</div>
              </template>
            </dx-autocomplete>
          </div>
        </div>
        <table class="table" v-if="form.opmes.length > 0">
          <thead>
          <tr>
            <th style="width:50px">#</th>
            <th style="width:100px">Código</th>
            <th>Descrição</th>
            <th class="text-center" style="width:90px">Quantidade</th>
            <th style="width:40px"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in form.opmes" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ item.code | tuss }}</td>
            <td>
              <input type="text" class="form-input input-sm" v-model="item.name">
            </td>
            <td class="text-center">
              <input
                type="number" class="form-input input-sm"
                v-model="item.quantity" min="1"
              >
            </td>
            <td class="text-right">
              <button class="btn btn-sm btn-action btn-icon btn-error"
                      @click="removeOpme(i)">
                <fa-icon :icon="['fal', 'times']"></fa-icon>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </template>
    </div>
    <template slot="footer">
      <button class="btn btn-primary mr-1"
              @click="save()" v-if="form.professionals.length <= 1"
              :disabled="saving" :class="{loading: saving}">
        Salvar
      </button>
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import formMixin from '@/mixins/form';
import { randomHash } from '@/helpers';

export default {
  props: {
    show: {
      type: Boolean,
    },
    id: {
      type: String,
    },
    user: {
      type: Object,
    },
  },
  mixins: [formMixin],
  data() {
    return {
      path: '/request-templates',
      isNew: true,
      loading: false,
      saving: false,
      expense: '',
      opme: '',
      form: this.blankForm(),
    };
  },
  async mounted() {
    this.isNew = this.id === '';
    if (!this.isNew) {
      this.form.id = this.id;
      this.load();
    }
  },
  validations() {
    return {
      form: {
        name: { required },
        type: { required },
        active: { required },
      },
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    load() {
      this.loading = true;

      return this.$http
        .get(`${this.path}/${this.form.id}`)
        .then(({ data }) => {
          if (data) {
            this.form = data;
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      this.saving = true;

      const data = this.clone(this.form);

      data.professionals = [{
        id: this.user.id,
        name: this.user.name,
      }];

      const request = !data.id
        ? this.$http.post(this.path, data)
        : this.$http.put(`${this.path}/${data.id}`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(() => {
          this.$toast.show('Registro salvo');
          this.close();
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
    removeExpense(idx) {
      this.form.expenses.splice(idx, 1);
    },
    findExpense(search) {
      const params = {
        types: 'procedure',
        search,
        limit: 100,
      };
      return this.$http.get('/tuss', { params })
        .then(({ data }) => data.items);
    },
    setExpense(data) {
      this.expense = null;
      const found = this.form.expenses.find(item => item.id === data.id);
      if (found) {
        found.quantity += 1;
      } else {
        this.form.expenses.push({
          key: randomHash(),
          id: data.id,
          code: data.code,
          name: data.name,
          quantity: 1,
        });
      }
    },
    removeOpme(idx) {
      this.form.opmes.splice(idx, 1);
    },
    findOpme(search) {
      const params = {
        types: 'material',
        search,
        limit: 100,
      };
      return this.$http.get('/tuss', { params })
        .then(({ data }) => data.items);
    },
    setOpme(data) {
      this.opme = null;
      const found = this.form.opmes.find(item => item.id === data.id);
      if (found) {
        found.quantity += 1;
      } else {
        this.form.opmes.push({
          key: randomHash(),
          id: data.id,
          code: data.code,
          name: [data.technicalName, data.model].filter(v => !!v).join(' '),
          quantity: 1,
        });
      }
    },
    blankForm() {
      return {
        id: '',
        name: '',
        type: '',
        professionals: [],
        expenses: [],
        opmes: [],
        active: true,
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.modal-template-request-detail {
  .card-detail {
    background-color: lighten($gray-color-light, 6%);
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    margin-bottom: $layout-spacing;
    padding: $layout-spacing;
    .card-detail-title {
      font-weight: bold;
      color: $primary-color;
      margin-bottom: $layout-spacing;
    }
    .table th, .table td {
      font-size: 0.52rem;
    }
  }
}
.modal-template-request-edit {
  margin-left: auto;
  margin-right: auto;
  max-width: $control-width-lg;
  .card-body {
    .table {
      margin-bottom: $layout-spacing-lg;
      tbody tr {
        background-color: $gray-color-light;
      }
      td {
        border-bottom-color: $bg-color;
      }
    }
  }
}
</style>
