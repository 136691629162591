<template>
  <dx-modal
    title="Configurações"
    size="sm"
    :value="show"
    @input="close"
  >
    <div class="stenci-title">Painel de chamadas:</div>
    <div class="form-group">
      <label for="room-name" class="form-label">Nome da sala</label>
      <input class="form-input" v-model="form.roomName">
    </div>
    <div class="form-group">
      <label for="place-id" class="form-label">Chamar pacientes em</label>
      <select class="form-select" v-model="form.placeId" id="place-id">
        <option value="">[Selecione]</option>
        <option
          v-for="item in places"
          :value="item._id"
          :key="item._id"
        >{{ item.name }}</option>
      </select>
    </div>
    <template v-slot:footer>
      <button class="btn" @click="close">Fechar</button>
      <button
        class="btn ml-1 btn-primary"
        :class="{ loading: processing }"
        @click="save"
      >Salvar</button>
    </template>
  </dx-modal>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState,
  mapMutations,
} from 'vuex';
import { pcsAdminUrl } from '@/config';
import { mergeFrom } from '@/helpers/object';

export default {
  data() {
    return {
      form: this.blankForm(),
      places: [],
      initiated: false,
    };
  },
  watch: {
    show() {
      if (this.show && !this.initiated) {
        this.init();
      }
    },
  },
  computed: {
    ...mapState({
      user: ({ auth }) => auth.user,
      show: ({ attendance }) => attendance.config.show,
      processing: ({ attendance }) => attendance.config.processing,
    }),
    ...mapGetters({
      hasModule: 'hasModule',
    }),
    hasPCSModule() {
      return this.hasModule('pcs');
    },
  },
  methods: {
    ...mapMutations({
      close: 'Attendance.CLOSE_CONFIG',
    }),
    ...mapActions({
      loadConfig: 'loadAttendanceConfig',
      saveConfig: 'saveAttendanceConfig',
    }),
    async init() {
      const data = await this.loadConfig();
      if (data) {
        this.form = mergeFrom(this.form, data);
      }
      await this.loadPlaces();
      this.initiated = true;
    },
    async loadPlaces() {
      const params = {
        offset: 0,
        limit: 20,
        identityId: this.user.branch.identityId,
      };
      const { data: places } = await this.$http.get(`${pcsAdminUrl}/place`, { params });
      this.places = places;
    },
    async save() {
      await this.saveConfig(this.form);
      this.close();
    },
    blankForm() {
      return {
        placeId: '',
        roomName: '',
      };
    },
  },
};
</script>
