<template>
  <dx-modal title="Gerenciar receituário"
            :value="show"
            @input="close"
            size="lg">
    <div class="prescription-detail"
         v-if="form.professionals && form.professionals.length > 1">
      <strong class="text-warning">
        * Não é possível editar um receituário compartilhado. Crie seus próprios receituários
      </strong>
      <div class="card-detail mt-2">
        <small>Título</small>
        <div class="card-detail-title">{{ form.name }}</div>
        <div class="card-detail-data">
          <div class="columns"
               v-for="item in form.drugs" :key="item.key">
            <div class="column col-5 col-md-12 col-sm-12 form-group">
              <small>Medicamentos</small>
              <div>
                <span class="text-bold mr-2">{{ item.name }}</span>
                <small>x{{ item.quantity }}</small>
              </div>
            </div>
            <div class="column col-3 col-md-12 col-sm-12 form-group">
              <small>Posologia</small>
              <div>{{ item.dosage ? item.dosage : '-' }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-detail">
        <small>Compartilhado com</small>
        <div class="card-detail-data">
          <div v-for="(professional, i) in form.professionals"
               :key="professional.key">
            {{ i + 1 }} - {{ professional.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="modal-template-prescription-edit" v-else>
      <div class="form-group"
           :class="{'has-error': $v.form.name.$error}">
        <label for="name" class="form-label">Nome</label>
        <input type="text" id="name" name="name"
               v-model="form.name" class="form-input"
               @change="$v.form.name.$touch()">
        <template v-if="$v.form.name.$error">
          <div class="form-input-hint"
               v-if="!$v.form.name.required">Campo obrigatório</div>
        </template>
      </div>
      <div class="card-prescription-body">
        <div class="columns">
          <div class="column col-6">
            <label class="form-label">Pesquisar medicamento</label>
            <dx-autocomplete
              id="drug"
              ref="drug"
              v-model="drug"
              :source="findDrug"
              label="drug"
              track-by="drug-id"
              @select="setDrug"
              :debounce="800"
              placeholder="Pesquisar medicamento">
              <button slot="action"
                      class="btn btn-neutral btn-action input-group-btn btn-icon">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
              <template v-slot="{ item }">
                <strong class="text-primary">
                  <fa-icon :icon="['fal', 'capsules']"></fa-icon>
                  {{ item.name }}
                </strong>
                <small v-if="item.caption">{{ item.caption }}</small>
              </template>
            </dx-autocomplete>
          </div>
        </div>
        <div class="divider" />
        <item :data="item" v-for="(item, i) in form.drugs" :key="item.key"
              @remove="removeDrug(i)"/>
        <label class="form-label">Outras informações</label>
        <textarea id="notes" rows="5" class="form-input" v-model="form.notes"
                  placeholder="Outras informações..."/>
      </div>
    </div>
    <template slot="footer">
      <button class="btn btn-primary mr-1" @click="save()"
              v-if="form.professionals && form.professionals.length <= 1"
              :disabled="saving" :class="{loading: saving}">
        Salvar
      </button>
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import formMixin from '@/mixins/form';
import { randomHash } from '@/helpers';
import Item from '../../../../medical-record/components/recipe/card/Item.vue';

export default {
  mixins: [formMixin],
  components: {
    Item,
  },
  props: {
    show: {
      type: Boolean,
    },
    id: {
      type: String,
    },
    user: {
      type: Object,
    },
  },
  data() {
    return {
      path: '/recipe-templates',
      isNew: true,
      loading: false,
      saving: false,
      drug: '',
      form: this.blankForm(),
    };
  },
  async mounted() {
    this.isNew = this.id === '';
    if (!this.isNew) {
      this.form.id = this.id;
      this.load();
    }
  },
  validations() {
    return {
      form: {
        name: { required },
        active: { required },
      },
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    load() {
      this.loading = true;

      return this.$http
        .get(`${this.path}/${this.form.id}`)
        .then(({ data }) => {
          if (data) {
            this.form = data;
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error || this.form.professionals.length > 1) {
        return;
      }

      this.saving = true;

      const data = this.clone(this.form);

      data.professionals = [{
        id: this.user.id,
        name: this.user.name,
      }];

      const request = !data.id
        ? this.$http.post(this.path, data)
        : this.$http.put(`${this.path}/${data.id}`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(() => {
          this.$toast.show('Registro salvo');
          this.close();
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
    removeDrug(idx) {
      this.form.drugs.splice(idx, 1);
    },
    findDrug(search) {
      return this.$http.get(`/medicines?search=${search}`)
        .then(({ data }) => {
          if (data.items && data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        });
    },
    setDrug(data) {
      this.drug = null;
      this.form.drugs.push({
        key: randomHash(),
        id: data.id,
        name: `${data.name} ${data.caption || ''}`,
        quantity: 1,
        unit: data.unit || 'packing',
        access: data.access || 'oral',
        dosage: '',
        specialControl: data.specialControl || false,
      });
    },
    blankForm() {
      return {
        id: '',
        name: '',
        professionals: [],
        drugs: [],
        notes: '',
        active: true,
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.prescription-detail {
  .card-detail {
    background-color: lighten($gray-color-light, 6%);
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    margin-bottom: $layout-spacing;
    padding: $layout-spacing;
    .card-detail-title {
      font-weight: bold;
      color: $primary-color;
      margin-bottom: $layout-spacing;
    }
    .table th, .table td {
      font-size: 0.52rem;
    }
  }
}
.modal-template-prescription-edit {
  margin-left: auto;
  margin-right: auto;
  max-width: $control-width-lg;
  .card-prescription-body {
    background-color: lighten($gray-color, 24%);
    margin: $layout-spacing-lg 0;
    padding: $layout-spacing-lg;
  }
  .divider {
    border-top: $border-width * 2 solid $light-color;
    margin: $layout-spacing-lg 0;
  }
}
</style>
