<template>
  <div>
    <dx-modal title="Meus modelos de receituários"
              :value="show"
              @input="close"
              size="lg"
              id="modal-template-prescription">
      <div class="columns">
        <div class="column form-group">
          <label for="filter-prescription-search" class="form-label">Pesquisar</label>
          <div class="input-group">
            <input type="text" id="filter-prescription-search"
                   name="filter-prescription-search" class="form-input"
                   v-model="filter.search" maxlength="100"
                   autocomplete="nope">
            <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                    tabindex="-1">
              <fa-icon :icon="['fal', 'search']"/>
            </button>
          </div>
        </div>
        <div class="column col-auto mb-2"
             style="display: flex; align-items: flex-end">
          <button class="btn btn-primary btn-icon btn-icon-left"
                  @click="create">
            <fa-icon :icon="['fal', 'plus']"/>
            Novo receituário
          </button>
        </div>
      </div>
      <div class="loading mt-2" v-if="loading"/>
      <template v-else>
        <template v-if="data.items.length === 0">
          <div class="empty mt-2">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
            </div>
            <p class="empty-title h5">Receituário</p>
            <p class="empty-subtitle">
              Nenhum receituário encontrado. Verifique os filtros para realizar novas buscas
            </p>
          </div>
        </template>
        <template v-else>
          <table class="table table-striped table-hover">
            <thead>
            <tr>
              <th class="hide-sm" width="40px">#</th>
              <th>Nome</th>
              <th class="hide-sm hide-md text-center">Compartilhado</th>
              <th class="hide-sm hide-md text-center">Ativo</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in data.items" :key="i">
              <td class="hide-sm">{{ i + data.offset + 1 }}</td>
              <td>
                <span class="hover-link" @click="edit(item)">{{ item.name }}</span>
              </td>
              <td class="hide-sm hide-md text-center">
                <span class="text-warning" v-if="item.professionals.length > 1">Sim</span>
                <span class="text-info" v-else>Não</span>
              </td>
              <td class="hide-sm hide-md text-center">
                {{ item.active ? 'Sim' : 'Não' }}
              </td>
              <td class="text-right">
                <button class="btn btn-sm btn-action btn-icon btn-secondary tooltip mr-1"
                        data-tooltip="Editar" @click="edit(item)">
                  <fa-icon :icon="['fal', 'pencil']"></fa-icon>
                </button>
                <button class="btn btn-sm btn-action btn-icon btn-error tooltip"
                        :class="{loading: deleting}" data-tooltip="Excluir"
                        :disabled="deleting || item.professionals.length > 1"
                        @click="remove(item.id, i)">
                  <fa-icon :icon="['fal', 'times']"></fa-icon>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="mt-2">
            <st-pagination :total="data.total"
                           :limit="data.limit" @paginate="paginate"></st-pagination>
            <span>Total de registros:
              <span class="ml-2 text-primary text-bold">{{ data.total }}</span>
            </span>
          </div>
        </template>
      </template>
      <template slot="footer">
        <button class="btn" @click="close">Sair</button>
      </template>
    </dx-modal>
    <form-modal
      v-if="editModel.show"
      :id="editModel.id"
      :user="user"
      :show="editModel.show"
      @close="closeModalEdit"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FormModal from './Form.vue';

export default {
  components: {
    FormModal,
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      path: '/recipe-templates',
      loading: false,
      deleting: false,
      filter: {
        search: '',
      },
      editModel: {
        id: '',
        show: false,
      },
      data: {
        items: [],
        limit: 20,
        offset: 0,
      },
    };
  },
  async mounted() {
    this.load();
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.search, 600);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  methods: {
    search() {
      this.data.offset = 0;
      this.load();
    },
    load() {
      this.loading = true;

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
        professionalId: this.user.id,
      };

      if (this.filter.search.trim().length > 0) {
        params.search = this.filter.search;
      }

      this.$http.get(this.path, { params })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {
        })
        .then(() => {
          this.loading = false;
        });
    },
    remove(id, i) {
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este receituário?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.deleting = true;
              this.$http
                .delete(`${this.path}/${id}`)
                .then(() => {
                  this.data.items.splice(i, 1);
                  this.data.total -= 1;
                })
                .catch(() => {
                  this.$toast.show('Não foi possível excluir', { type: 'error' });
                })
                .then(() => {
                  this.deleting = false;
                });
              close();
            },
          },
        ],
      });
    },
    create() {
      this.editModel.id = '';
      this.editModel.show = true;
    },
    close() {
      this.$emit('close');
    },
    edit(item) {
      this.editModel.id = item.id;
      this.editModel.show = true;
    },
    closeModalEdit() {
      this.load();
      this.editModel.show = false;
    },
    paginate({ offset }) {
      this.data.offset = offset;
      this.load();
    },
  },
};
</script>

<style lang="scss">
#modal-template-prescription {
}
</style>
